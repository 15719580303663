<template>
    <div id="TerraTaskList">
        <h1>云计算任务列表</h1>
        <Table height="280" size="small" :columns="columns" :data="data" @on-row-dblclick="TerraTaskDblclick"></Table>
        <div class="PageBox">
            <Page simple  :total="total"  :page-size="limit" :current="page" @on-change="changePage"/>
        </div>
        <Spin fix v-if="startZipTaskStatus">
            <h1 style=" color: #29b8f8;font-size: 16px ; margin-bottom: 30px">资源包转移中...</h1>
        </Spin>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                client:null,
                columns: [
                    {
                        title: '文件名',
                        align:"center",
                        width:"160",
                        tooltip:true,
                        key: 'task_name'
                    },
                    {
                        title: '状态',
                        align:"center",
                        width:"280",
                        key: 'state',
                        // 0初始状态
                        // 1已上传原始图片
                        // 2正在正射计算
                        // 3正在模型计算
                        // 4完成正射运算任务
                        // 5完成模型运算任务
                        // 6正在正射压缩
                        // 7正在模型压缩
                        // 8完成正射压缩任务
                        // 9完成模型压缩任务
                        // 10正射压缩资源转移到自已服务端
                        // 11模型压缩资源转移到自已服务端
                        // 12执行失败
                        // 13正射资源包转移中
                        // 14模型资源包转移中
                        render: (h, params) => {
                            if(params.row.state==0){
                                return h('div', [
                                    h('span', {
                                        style: {
                                            fontSize: '12px',
                                            color:'#FF9500'
                                        },
                                        on: {
                                            click: () => {

                                            }
                                        }
                                    }, '任务未开始'),
                                ]);
                            }else if(
                                params.row.state==2||
                                params.row.state==3||
                                params.row.state==6||
                                params.row.state==7
                            ){
                                return h('div',[
                                    h('span', {
                                        style: {
                                            fontSize: '12px',
                                            color:'#979797'
                                        },
                                        on: {
                                            click: () => {

                                            }
                                        }
                                    },params.row.state==2?'正射':params.row.state==3?'模型':params.row.state==6?"正射压缩":'模型压缩'),
                                    h('Progress', {
                                        props: {
                                            status:"active",
                                            percent:Number(params.row.progress),
                                        },
                                        style: {
                                            width: '180px',
                                        },
                                        on: {
                                            click: () => {

                                            }
                                        }
                                    }, )
                                ]);
                            }else if(
                                params.row.state==4||
                                params.row.state==5||
                                params.row.state==8||
                                params.row.state==9
                            ){
                                return h('div', [
                                    h('span', {
                                        style: {
                                            fontSize: '12px',
                                            color:'#FF9500'
                                        },
                                        on: {
                                            click: () => {

                                            }
                                        }
                                    }, '等待操作'),
                                ]);
                            }else if(params.row.state==13){
                                return h('div', [
                                    h('span', {
                                        style: {
                                            fontSize: '12px',
                                            color:'#61D76B'
                                        },
                                        on: {
                                            click: () => {

                                            }
                                        }
                                    }, '正射资源绑定中'),
                                ]);
                            }else if(params.row.state==14){
                                return h('div', [
                                    h('span', {
                                        style: {
                                            fontSize: '12px',
                                            color:'#61D76B'
                                        },
                                        on: {
                                            click: () => {

                                            }
                                        }
                                    }, '模型资源绑定中'),
                                ]);
                            }else if(params.row.state==10){
                                return h('div', [
                                    h('span', {
                                        style: {
                                            fontSize: '12px',
                                            color:'#61D76B'
                                        },
                                        on: {
                                            click: () => {

                                            }
                                        }
                                    }, '正射资源已绑定'),
                                ]);
                            }else if(params.row.state==11){
                                return h('div', [
                                    h('span', {
                                        style: {
                                            fontSize: '12px',
                                            color:'#61D76B'
                                        },
                                        on: {
                                            click: () => {

                                            }
                                        }
                                    }, '模型资源已绑定'),
                                ]);
                            }else if(params.row.state==13){
                                return h('div', [
                                    h('span', {
                                        style: {
                                            fontSize: '12px',
                                            color:'#61D76B'
                                        },
                                        on: {
                                            click: () => {

                                            }
                                        }
                                    }, '云计算任务完成'),
                                ]);
                            }else if(params.row.state==12){
                                return h('div', [
                                    h('span', {
                                        style: {
                                            fontSize: '12px',
                                            color:'#FF685B'
                                        },
                                        on: {
                                            click: () => {

                                            }
                                        }
                                    }, '图片格式错误，请重新拍摄'),
                                ]);
                            }

                        }
                    },
                    {
                        title: '已完成',
                        align:"center",
                        width:"80",
                        key: 'age',
                        render: (h, params) => {
                            return h('div', {
                                style: {

                                },
                            },
                            [
                                h('span', {
                                    style: {
                                        fontSize: '12px',
                                        color:'#555',
                                    },
                                    on: {
                                        click: () => {

                                        }
                                    }
                                }, params.row.is_all_end==0?"无":params.row.is_all_end==1?"正射":params.row.is_all_end==2?"模型":"全部完成"),

                            ]);
                        }
                    },
                    {
                        title: '操作',
                        align:"center",
                        key: 'address',
                        render: (h, params) => {
                            return h('div', {
                                    style: {
                                        display:'flex',
                                        justifyContent:"center"
                                    },
                                },
                                [
                                h('i', {
                                    class:"ivu-icon ivu-icon-ios-play",
                                    style: {
                                        fontSize: '18px',
                                        color:'#61D76B',
                                        cursor:'pointer',
                                        display:params.row.state==4||
                                        params.row.state==5||
                                        params.row.state==8||
                                        params.row.state==9?"block":"none"
                                    },
                                    on: {
                                        click: () => {
                                            switch ( params.row.state) {
                                                case 4:{// 4完成正射运算任务
                                                    console.log(params.row.state);
                                                    this.startZipTask(params.row,1);//开启正射压缩任务
                                                    break;
                                                }
                                                case 5:{// 5完成模型运算任务
                                                    console.log(params.row.state);
                                                    this.startZipTask(params.row,2);//开启模型压缩任务
                                                    break;
                                                }
                                                case 8:{// 8完成正射压缩任务
                                                    //开启正射压缩资源转移到自已服务端
                                                    console.log(params.row.state);
                                                    this.gunzipTo(params.row,'Zip2D');
                                                    break;
                                                }
                                                case 9:{// 8完成模型压缩任务
                                                    //开启正射压缩资源转移到自已服务端
                                                    console.log(params.row.state);
                                                    this.gunzipTo(params.row,'Zip3D');
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }, ),
                                h('i', {
                                    class:"ivu-icon ivu-icon-md-trash",
                                    style: {
                                        fontSize: '18px',
                                        color:'#f3493b',
                                        cursor:'pointer',
                                    },
                                    on: {
                                        click: () => {
                                            this.$Modal.confirm({
                                                title: '删除云计算任务',
                                                content: '<p>删除后任务不可恢复,谨慎删除!</p>',
                                                onOk:()=>{
                                                    this.$Java_post("/terra_task/deleteTask", {
                                                        "terra_id": params.row.id
                                                    }).then(res=> {
                                                        console.log("删除Terra任务",res);
                                                        if (res.code == 1) {
                                                            this.getAllTask(this.page,this.progress);
                                                        } else {
                                                            this.$Message.error(res.msg_customer);
                                                        }
                                                    });
                                                }
                                            });

                                        }
                                    }
                                }, ),

                            ]);
                        }
                    }
                ],
                data: [],
                page:1,
                limit:5,
                total:0,
                progress:[],
                startZipTaskStatus:false,
                aaa:null
            };
        },
        props: {
            OSSdata: {
                type: Object,
                default: () => {}
            }
        },
        mounted() {
            this.getAllTask(1,[]);
            this.aaa=setInterval(()=>{
                this.getAllTask(this.page,this.progress);
                console.log(this.progress);
            },5000)
        },
        beforeDestroy() {
            clearInterval(this.aaa);
        },
        methods: {
            //获取云计算任务列表
            getAllTask(ind,progress){
                console.log(progress);
                let _this=this;
                this.$Java_post("/terra_task/getAllTask", {
                    "page": ind,
                    "limit": this.limit,
                    "progress_list":progress
                }).then(res=> {
                    console.log("获取当前云计算任务的数据",res);
                    if (res.code == 1) {
                        _this.progress=[];
                        this.total=res.data.total;
                        this.page=res.data.page;
                        this.data=res.data.rows;
                        res.data.rows.forEach((item,ind)=>{
                            item.child_task.forEach((item2,ind2)=>{
                                let names=item2.data_name.split('_');
                                if(
                                    names[names.length-1]==='Job2D'||
                                    names[names.length-1]==='Job3D'||
                                    names[names.length-1]==='ZipJob2D'||
                                    names[names.length-1]==='ZipJob3D'
                                ){
                                    console.log('执行');
                                    _this.getTerraJobs(item2,ind);
                                }
                            })

                        })

                    } else {
                        this.$Message.error(res.msg_customer);
                    }
                });
            },
            //从Terra查询作业详情
            getTerraJobs(item,ind){
                let names=item.data_name.split('_');
                let _this=this;


                if (item.uuid=='') {
                    console.log(item);
                    return;
                }

                _this.$T_get(`/terra-hz/terra-rescon-be/v2/jobs/${item.uuid}`).then(res => {
                    console.log('T_get',res.data);
                    if (res.result.code === 0) {
                        //0：待开始，1：等待中，2：准备中，3：执行中，4：处理结果中，5：已停止，6：执行完成，7：执行失败
                        let data={};
                        if(
                            res.data.status==0
                        ){
                            switch (names[names.length-1]) {
                                case 'Job2D':
                                    data={
                                        Job2D:{
                                            id:item.id,
                                            job_state:'0'
                                        }
                                    };
                                    //0：待开始 1：执行中  2:执行完成，3：执行失败
                                    break;
                                case 'Job3D':
                                    data={
                                        Job3D:{
                                            id:item.id,
                                            job_state:'0'
                                        }
                                    };
                                    break;
                                case 'ZipJob2D':
                                    data={
                                        ZipJob2D:{
                                            id:item.id,
                                            job_state:'0'
                                        }
                                    };
                                    break;
                                case 'ZipJob3D':
                                    data={
                                        ZipJob3D:{
                                            id:item.id,
                                            job_state:'0'
                                        }
                                    };
                                    break;
                            }
                            _this.$Java_post("/terra_task/modify_child", data).then(J_res=> {
                                console.log("上传资源作业名称储存到后台",J_res);
                                if (J_res.code == 1) {

                                } else {
                                    this.$Message.error(J_res.msg_customer);
                                }
                            });
                        }else if(
                            res.data.status==1||
                            res.data.status==2||
                            res.data.status==3||
                            res.data.status==4
                        ){
                            switch (names[names.length-1]) {
                                case 'Job2D':
                                    data={
                                        Job2D:{
                                            id:item.id,
                                            job_state:'1'
                                        }
                                    };
                                    //0：待开始 1：执行中 2:执行完成，3：执行失败
                                    break;
                                case 'Job3D':
                                    data={
                                        Job3D:{
                                            id:item.id,
                                            job_state:'1'
                                        }
                                    };
                                    break;
                                case 'ZipJob2D':
                                    data={
                                        ZipJob2D:{
                                            id:item.id,
                                            job_state:'1'
                                        }
                                    };
                                    break;
                                case 'ZipJob3D':
                                    data={
                                        ZipJob3D:{
                                            id:item.id,
                                            job_state:'1'
                                        }
                                    };
                                    break;
                            }
                            _this.$Java_post("/terra_task/modify_child", data).then(J_res=> {
                                console.log("上传资源作业名称储存到后台",J_res);
                                if (J_res.code == 1) {
                                    console.log('进度条',res.data.percentage);
                                    function myIsNaN(value) {
                                        return typeof value === 'number' && !isNaN(value);
                                    }
                                    if(myIsNaN(res.data.percentage)){
                                        _this.progress.push({
                                            "id": item.terra_task_id,
                                            "progress":Math.floor(res.data.percentage*100)==100?Math.floor(res.data.percentage*100)-1:Math.floor(res.data.percentage*100)
                                        })
                                    }else {
                                        _this.progress.push({
                                            "id": item.terra_task_id,
                                            "progress":0
                                        })
                                    }
                                } else {
                                    this.$Message.error(J_res.msg_customer);
                                }
                            });
                        }else if(
                            res.data.status==5||
                            res.data.status==6
                        ){
                            console.log(names[names.length - 1]);
                            switch (names[names.length-1]) {
                                case 'Job2D':
                                    data={
                                        Job2D:{
                                            id:item.id,
                                            job_state:'2'
                                        }
                                    };
                                    //0：待开始 1：执行中  2:执行完成，3：执行失败
                                    break;
                                case 'Job3D':
                                    data={
                                        Job3D:{
                                            id:item.id,
                                            job_state:'2'
                                        }
                                    };
                                    break;
                                case 'ZipJob2D':
                                    data={
                                        ZipJob2D:{
                                            id:item.id,
                                            job_state:'2'
                                        }
                                    };
                                    break;
                                case 'ZipJob3D':
                                    data={
                                        ZipJob3D:{
                                            id:item.id,
                                            job_state:'2'
                                        }
                                    };
                                    break;
                            }
                             _this.$Java_post("/terra_task/modify_child", data).then(J_res=> {
                                console.log("上传资源作业名称储存到后台",J_res);
                                if (J_res.code == 1) {

                                } else {
                                    this.$Message.error(J_res.msg_customer);
                                }
                            });

                        }else if(res.data.status==7){
                            switch (names[names.length-1]) {
                                case 'Job2D':
                                    data={
                                        Job2D:{
                                            id:item.id,
                                            job_state:'3'
                                        }
                                    };
                                    //0：待开始 1：执行中  2:执行完成，3：执行失败
                                    break;
                                case 'Job3D':
                                    data={
                                        Job3D:{
                                            id:item.id,
                                            job_state:'3'
                                        }
                                    };
                                    break;
                                case 'ZipJob2D':
                                    data={
                                        ZipJob2D:{
                                            id:item.id,
                                            job_state:'3'
                                        }
                                    };
                                    break;
                                case 'ZipJob3D':
                                    data={
                                        ZipJob3D:{
                                            id:item.id,
                                            job_state:'3'
                                        }
                                    };
                                    break;

                            }
                            _this.$Java_post("/terra_task/modify_child", data).then(J_res=> {
                                console.log("上传资源作业名称储存到后台",J_res);
                                if (J_res.code == 1) {

                                } else {
                                    this.$Message.error(J_res.msg_customer);
                                }
                            });
                        }

                    }
                }).catch(err => {
                    console.log(err);
                    _this.$notice.error({
                        title: "查询作业详情失败!",
                        desc: err.toString()
                    });
                });
            },
            changePage(ind){
                this.getAllTask(ind,[])
            },
            //双击云计算任务列表进入任务
            TerraTaskDblclick(row,ind){
                console.log(row,ind);
                // if(row.is_all_end==3){
                //     console.log('已完成所有计算任务');
                //     return
                // }
                // this.$Java_post("/project/task/figure/detail", {
                //     bf_id:row.bf_id
                // }).then(res=> {
                //     console.log("上传资源作业名称储存到后台",res);
                //     if (res.code == 1) {
                //         sessionStorage.setItem("getTaskData",JSON.stringify(res.data));
                //         sessionStorage.setItem("TerraTaskID",JSON.stringify(row.id));
                //         setTimeout(()=>{
                //             this.$router.push({
                //                 path: "/resourceIndex/uploadingPage",
                //                 query: {
                //                     TaskID:row.id,
                //                     status:'createJob',
                //                     is_all_end:row.is_all_end
                //                 }
                //             });
                //         },400);
                //
                //     } else {
                //         this.$Message.error(res.msg_customer);
                //     }
                // });

            },
            //开启Terra压缩作业
            startZipTask(row,type){
                var _this=this;

                this.$Java_post("/terra_task/getChildTask", {
                    "terra_task_id": row.id
                }).then(res=> {
                    if (res.code == 1) {
                        if(type==1){//1正射资源压缩,2模型资源压缩
                            _this.$T_post(`/terra-hz/terra-rescon-be/v2/jobs/${res.data.ZipJob2D.uuid}/start`,{
                                parameters: "null",
                                resourceUuid: res.data.Res2D.uuid,
                                type: 3
                            }).then(res2 => {
                                console.log(res2.data);
                                if (res2.result.code === 0) {
                                    _this.$Java_post("/terra_task/modify_child", {Zip2D:{
                                            id:res.data.Zip2D.id,
                                            uuid:res2.data.Job.OutputResource.UUID
                                        }}).then(res3=> {
                                        console.log("修改压缩结果的uuid",res3);
                                        if (res3.code == 1) {
                                            this.getAllTask(this.page,[]);
                                        } else {
                                            this.$Message.error(res3.msg_customer);
                                        }

                                    });
                                }
                            }).catch(err => {
                                console.log(err);
                                _this.$notice.error({
                                    title: "开启作业失败!",
                                    desc: err.toString()
                                });
                            });
                        }else if(type==2){
                            _this.$T_post(`/terra-hz/terra-rescon-be/v2/jobs/${res.data.ZipJob3D.uuid}/start`,{
                                parameters: "null",
                                resourceUuid: res.data.Res3D.uuid,
                                type: 3
                            }).then(res2 => {
                                console.log('修改3D的uuid',res2.data.Job.OutputResource.UUID);
                                if (res2.result.code === 0) {
                                    _this.$Java_post("/terra_task/modify_child", {Zip3D:{
                                            id:res.data.Zip3D.id,
                                            uuid:res2.data.Job.OutputResource.UUID
                                        }}).then(res3=> {
                                        console.log("修改压缩结果的uuid",res3);
                                        if (res3.code == 1) {
                                            this.getAllTask(this.page,[]);
                                        } else {
                                            this.$Message.error(res3.msg_customer);
                                        }

                                    });
                                }
                            }).catch(err => {
                                console.log(err);
                                _this.$notice.error({
                                    title: "开启作业失败!",
                                    desc: err.toString()
                                });
                            });
                        }

                    } else {
                        this.$Message.error(res.msg_customer);
                    }
                });

            },
            //正射压缩资源转移到自已服务端
            gunzipTo(data,name){
                this.startZipTaskStatus=true;
                console.log(data,name);
                let _this=this;
                if(name=='Zip2D'){
                    data.child_task.forEach((item,ind)=>{
                        let names=item.data_name.split('_');
                        if(names[names.length-1]==='Zip2D'){
                            this.$T_get(`/terra-hz/terra-rescon-be/v2/files?needURL=true&resourceUuid=${item.uuid}`).then(res => {
                                console.log(res.data.list[0].url);
                                if (res.result.code === 0) {
                                    this.$Java_post("/terra_task/transferZip", {
                                        "bf_id": data.bf_id,
                                        "load_url": res.data.list[0].url,//下载地址
                                        "map_name": data.task_name,//名字
                                        "map_area": 0,
                                        "shoot_date": (new Date()).toLocaleDateString(),
                                        "image_number": 0,
                                        "map_type": 1,//1正射 2模型
                                        "terra_task_id": data.id//云计算任务id
                                    }).then(res3=> {
                                        console.log("正射资源转移到自已服务端",res3);
                                        if (res3.code == 1) {
                                            this.startZipTaskStatus=false;
                                            this.getAllTask(this.page,[]);
                                        } else {
                                            this.$Message.error(res3.msg_customer);
                                        }

                                    });
                                }
                            }).catch(err => {
                                console.log(err);
                                _this.$notice.error({
                                    title: "查询正射文件失败!",
                                    desc: err.toString()
                                });
                            });

                        }
                    });


                }else if(name=='Zip3D'){
                    data.child_task.forEach((item,ind)=>{
                        let names=item.data_name.split('_');
                        if(names[names.length-1]==='Zip3D'){
                            this.$T_get(`/terra-hz/terra-rescon-be/v2/files?needURL=true&resourceUuid=${item.uuid}`).then(res => {
                                console.log(res.data.list[0].url);
                                if (res.result.code === 0) {
                                    this.$Java_post("/terra_task/transferZip", {
                                        "bf_id": data.bf_id,
                                        "load_url": res.data.list[0].url,//下载地址
                                        "map_name": data.task_name,//名字
                                        "map_area": 0,
                                        "shoot_date": (new Date()).toLocaleDateString(),
                                        "image_number": 0,
                                        "map_type": 2, //1正射 2模型
                                        "terra_task_id": data.id //云计算任务id
                                    }).then(res3=> {
                                        console.log("模型资源转移到自已服务端",res3);
                                        if (res3.code == 1) {
                                            this.startZipTaskStatus=false;
                                            this.getAllTask(this.page,[]);
                                        } else {
                                            this.$Message.error(res3.msg_customer);
                                        }

                                    });
                                }
                            }).catch(err => {
                                console.log(err);
                                _this.$notice.error({
                                    title: "查询模型文件失败!",
                                    desc: err.toString()
                                });
                            });

                        }
                    });
                }
            }
        },

    };
</script>

<style scoped lang="scss">
    #Terra{
        width: 100%;
        height: 100%;
        h1{
            font-size: 16px;
            font-weight: 600;
            line-height: 50px;
            margin-left: 20px;
            color: #333333;

        }
        .PageBox{
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
</style>
